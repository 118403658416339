import { FC, useState } from 'react';

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { StatusRound } from 'shared/assets/icons';
import { TMethods } from 'shared/services/types/methods.types';

interface IProps {
  useParamsMethodID: string;
  methodsList: TMethods.MethodItem[];
  onChange?: (e: SelectChangeEvent) => void;
}

export const MethodSelect: FC<IProps> = ({ useParamsMethodID, methodsList, onChange }) => {
  const [methodID, setMethodID] = useState(useParamsMethodID);
  const navigate = useNavigate();
  const handleChange = (event: SelectChangeEvent) => {
    setMethodID(event.target.value as string);
    navigate(`/method/${event.target.value}`);
  };
  return (
    <Select
      value={methodID}
      onChange={onChange ?? handleChange}
      name='methodsDataelect'
      sx={{
        borderRadius: '100px',
        color: '#185AC2',
        minWidth: '176px',
        '.MuiSelect-select': {
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '10px',
          padding: '10px 33px 10px 16px',
          '&[aria-expanded="true"]': {}
        },
        '.MuiSvgIcon-root': {
          color: '#185AC2'
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#185AC2'
        },
        '&:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#185AC2'
          }
        }
      }}>
      {methodsList?.map(({ id, name, is_active }) => (
        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
          value={id}
          key={id}>
          {name}
          <StatusRound style={is_active ? { color: '#167E55' } : { color: '#BA1A1A' }} />
        </MenuItem>
      ))}
    </Select>
  );
};
