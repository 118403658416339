import { ChangeEvent, useState } from 'react';

import { UpdateOutlined } from '@mui/icons-material';
import { Box, Divider, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';

import { StyledSwitch } from 'shared/ui/switch-styled';

export default function CustomThemeTextField() {
  const [value, setValue] = useState<number | undefined>();
  const [checked, setChecked] = useState(false);

  const handleSwitch = async (event?: ChangeEvent<HTMLInputElement>, ids?: string) => {
    const isChecked = event?.target.checked;
    setChecked(!isChecked);
  };

  const tabData = [
    {
      label: 'Update',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography component={'p'}>Include new table version</Typography>
          <StyledSwitch
            value={checked}
            setValue={e => {
              handleSwitch(e);
            }}
          />
        </Box>
      ),
      icon: <UpdateOutlined />
    }
  ];

  return (
    <Stack direction='row' minHeight='260px'>
      <Box sx={{ minWidth: '228px', display: 'flex', flexDirection: 'column' }}>
        <Typography
          component={'h1'}
          sx={{
            color: '#185AC2',
            fontSize: '26px',
            fontFamily: 'Roboto',
            fontWeight: '500',
            lineHeight: '36px',
            wordWrap: 'break-word',
            p: '12px 24px'
          }}>
          Settings
        </Typography>
        <Tabs
          orientation='vertical'
          value={value}
          onChange={(_event, newValue) => setValue(newValue)}>
          {tabData.map((tab, index) => (
            <Tab
              key={index}
              sx={{
                p: 0,
                pl: '24px',
                alignItems: 'flex-start',
                color: '#185AC2',
                '&.Mui-selected': {
                  color: '#185AC2',
                  background: 'rgba(28, 105, 225, 0.18)'
                }
              }}
              label={
                <Box>
                  <Stack direction='row' gap={1}>
                    {tab.icon}
                    <Typography whiteSpace='nowrap' sx={{ textAlign: 'left' }}>
                      {tab.label}
                    </Typography>
                  </Stack>
                </Box>
              }
            />
          ))}
        </Tabs>
      </Box>
      <Divider />
      {tabData.map(
        (tab, index) =>
          value === index && (
            <Paper sx={{ minWidth: '322px' }}>
              <Typography
                component={'h1'}
                sx={{
                  color: '#185AC2',
                  fontSize: '26px',
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  lineHeight: '36px',
                  wordWrap: 'break-word',
                  p: '12px 24px'
                }}>
                {tab.label}
              </Typography>
              <Box
                sx={{
                  p: '12px 24px',
                  color: '#44464F',
                  fontFamily: 'Roboto',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal'
                }}>
                {tab.content}
              </Box>
            </Paper>
          )
      )}
    </Stack>
  );
}
