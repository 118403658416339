import { FC, useRef, useState } from 'react';

import { Notifications, Security, Settings } from '@mui/icons-material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  Popover,
  Tooltip
} from '@mui/material';
import { useAuthStore } from 'app/store';
import { format } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';

import { CloseIcon, LogOut, LogoIcon, MethodsIcon } from 'shared/assets/icons';
import { useUser } from 'shared/hooks';
import { AuthService } from 'shared/services/auth/auth.service';
import { TMethods } from 'shared/services/types/methods.types';

import CustomThemeTextField from './test';

interface IProps {
  methods?: TMethods.MethodItem[];
}

export const SideNav: FC<IProps> = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const spanRef = useRef();

  const open = Boolean(anchorEl);
  function handleOpen() {
    setAnchorEl(spanRef.current ?? null);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const location = useLocation();
  const { pathname } = location;

  const { role, createdAt, rolesAccess, name } = useUser();

  const isAccess = rolesAccess(['Администратор', 'Менеджер']);

  const navigate = useNavigate();

  const { logout } = useAuthStore();

  const navbarList = {
    top: [
      {
        name: 'Methods',
        pathname: '/',
        icon: (color: string) => <MethodsIcon style={{ color }} />,
        onClick: () => navigate('/'),
        protected: false
      }
    ],
    bottom: [
      {
        name: (
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'start'}
            sx={{ span: { lineHeight: '20px' } }}>
            <span>{name}</span>
            <span>{role}</span>
            <span>{format(createdAt ? createdAt : new Date(0), 'dd.MM.yyyy')}</span>
          </Box>
        ),
        pathname: '/Avatar',
        icon: () => {
          return (
            <Avatar
              alt={`${name}`}
              src='../../shared/assets/icons/profile.png'
              sx={{ width: 24, height: 24 }}
            />
          );
        },
        onClick: () => {},
        protected: false
      },
      {
        name: 'Users',
        icon: (color: string) => <PeopleAltOutlinedIcon style={{ color }} />,
        pathname: '/users',
        onClick: () => navigate('/users'),
        protected: !isAccess
      },
      {
        name: 'Settings',
        icon: (color: string) => <Settings style={{ color }} />,
        onClick: handleOpen
      },
      {
        name: 'Log out',
        icon: (color: string) => <LogOut style={{ color }} />,
        pathname: '/Log out',
        onClick: () => {
          logout();
          AuthService.logout();
        }
      }
    ]
  };

  const settings = [
    {
      icon: <Settings />,
      title: 'Общие настройки',
      description: 'Базовые параметры приложения и интерфейса'
    },
    {
      icon: <Notifications />,
      title: 'Уведомления',
      description: 'Настройка alerts и push-сообщений'
    },
    {
      icon: <Security />,
      title: 'Безопасность',
      description: 'Управление доступом и приватностью'
    }
  ];

  return (
    <>
      <Box
        position={'fixed'}
        height={'calc(100vh - 24px)'}
        gridColumn={1}
        gridRow='1 / 4'
        sx={{
          bgcolor: 'primary.main',
          color: '#fff',
          borderRadius: 4,
          borderBottomRightRadius: open ? 0 : 4,
          marginRight: 2,
          overflow: 'auto',
          zIndex: '1000',
          '& .MuiDivider-root': { borderColor: '#A4B1EA' },
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'stretch'
        }}>
        <Grid
          container
          sx={{
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}>
          <Grid
            item
            sx={{
              maxWidth: 1
            }}>
            <Box sx={{ p: 12 }}>
              <LogoIcon />
            </Box>
          </Grid>
          <Grid item>
            <List component='div'>
              {navbarList.top.map(item => {
                const selected = pathname === item.pathname ? '#fff' : 'currentColor';
                if (item.protected && !isAccess) return null;
                return (
                  <Tooltip key={item.name} title={item.name} arrow disableInteractive>
                    <ListItemButton itemType='Button' onClick={item.onClick}>
                      <ListItemIcon>{item.icon(selected)}</ListItemIcon>
                    </ListItemButton>
                  </Tooltip>
                );
              })}
            </List>
          </Grid>
        </Grid>
        <Grid item>
          <List component='div' sx={{ padding: 0, paddingBottom: 10 }}>
            {navbarList.bottom.map(item => {
              const selected = pathname === item.pathname ? '#fff' : '#8CB3F1';
              if (item.protected && !isAccess) return null;
              return (
                <Tooltip
                  title={item.name}
                  key={item.pathname}
                  placement='top'
                  ref={spanRef}
                  arrow
                  disableInteractive>
                  <ListItemButton onClick={item.onClick}>
                    <ListItemIcon>{item.icon(selected)}</ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              );
            })}
          </List>
        </Grid>
      </Box>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        elevation={4}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        <Box
          sx={{
            display: 'block',
            position: 'absolute',
            top: '16px',
            right: '17px',
            width: 25,
            height: 25,
            color: '#44464F',
            cursor: 'pointer'
          }}
          onClick={handleClose}>
          <CloseIcon />
        </Box>
        <CustomThemeTextField />
      </Popover>
    </>
  );
};
