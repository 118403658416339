import { toFormData } from 'axios';

import { axiosInstance } from 'shared/api';
import { getBanksUrl } from 'shared/api/config/api.config';

import { TBanks } from './types/banks.types';

export const BanksService = {
  async createBank(data: TBanks.CreateBank) {
    const response = await axiosInstance.post<TBanks.GetBanksSuccess>(
      getBanksUrl('/create-app'),
      toFormData(data)
    );

    return response;
  },

  async getBanks(data: TBanks.GetBanks) {
    const response = await axiosInstance.get<TBanks.GetBanksSuccess>(
      getBanksUrl('/get-bank-apps'),
      {
        params: data
      }
    );

    return response;
  }
};
