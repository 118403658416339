import { useQuery } from '@tanstack/react-query';

import { TwoFactorAuthService } from 'shared/services/two-factor-auth/twoFactorAuth.service';

export const useGetQR = () => {
  const {
    isLoading: isQRLoading,
    data: getQR,
    ...restData
  } = useQuery({
    queryFn: () => TwoFactorAuthService.getQR(),
    queryKey: ['get qr'],
    retry: 0
  });

  return { isQRLoading, getQR, ...restData };
};
