import { useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import { useGetMethods } from 'widgets/methods';

import { AddAccountModal } from 'features/add-account';
import { AddUserModal } from 'features/add-user/ui';
import { useActionUsers, useGetUsers } from 'features/users/api';

import { useSortSwitches } from 'shared/hooks';
import { TUsers } from 'shared/services/types/users.types';
import { HeaderWrapper } from 'shared/ui/header-wrapper';
import { addMessageToast } from 'shared/utils';

import { TableUsers } from './slices/TableUsers';

export const User = () => {
  const [userID, setUserID] = useState<number | null>(null);
  const [userMethodID, setUserMethodID] = useState<number | null>(null);
  const [isOpenAddUser, setIsOpenAddUser] = useState(false);
  const [isOpenAddAccount, setIsOpenAddAccount] = useState(false);
  const [userData, setUserData] = useState<TUsers.UserData>();
  const { methods: methodsItems } = useGetMethods();
  const [filters, setFilters] = useState({ page: 0, size: 20 });
  const { idsAll, setSwitchItem, reset: switchesReset } = useSortSwitches();

  const { usersData, isUsersLoading, isUsersFetching } = useGetUsers({
    page: filters.page,
    size: filters.size
  });

  const { update } = useActionUsers();

  const handleChange = (
    data: {
      id: number;
      is_active: boolean;
    }[]
  ) => {
    data.forEach(i => {
      updateUser({ user_id: i.id, is_active: i.is_active });
    });
    addMessageToast('Users successfully switched', 'success');
    switchesReset();
  };

  const updateUser = async (data: TUsers.UpdateUser) => {
    try {
      await update.mutateAsync(data);
    } catch (error) {
      addMessageToast(error);
    }
  };

  const onActionUser = (type: 'edit' | 'delete', data: TUsers.UserData) => {
    if (type === 'edit') {
      setUserData(data);
      setIsOpenAddUser(true);
    } else {
      // TODO удаление пользователя/пользователей
    }
  };

  useEffect(() => {
    if (!isOpenAddUser) {
      setUserData(undefined);
    }
  }, [isOpenAddUser]);

  const onCloseUserModal = () => {
    setIsOpenAddUser(false);
    setUserID(null);
    setUserData({} as TUsers.UserData);
  };

  const onCloseAccountsModal = () => {
    setIsOpenAddAccount(false);
  };

  return (
    <>
      <HeaderWrapper>
        <Typography
          variant='h3'
          color='primary'
          sx={{ flexGrow: 1, fontSize: 28, lineHeight: '36px' }}>
          Users
        </Typography>

        <Button
          variant='contained'
          onClick={() => {
            setIsOpenAddUser(true);
          }}
          sx={{ padding: '10px 24px', paddingRight: '28px', maxWidth: 162 }}
          startIcon={<Add />}>
          Add user
        </Button>
        {!!idsAll.length && (
          <Button
            variant='contained'
            onClick={() => {
              handleChange(idsAll);
            }}
            sx={{ p: '10px 24px', paddingRight: '28px' }}>
            Save
          </Button>
        )}
      </HeaderWrapper>

      <TableUsers
        methodsItems={methodsItems}
        setFilter={setFilters}
        setModalAccountAdd={setIsOpenAddAccount}
        setUserMethodID={setUserMethodID}
        setUserID={setUserID}
        setSwitchItem={setSwitchItem}
        isUsersFetching={isUsersFetching}
        isUsersLoading={isUsersLoading}
        usersData={usersData}
        onEditUser={(data: TUsers.UserData) => {
          onActionUser('edit', data);
        }}
      />

      <AddUserModal
        isOpen={isOpenAddUser}
        setIsOpen={setIsOpenAddUser}
        setIsOpenAddAccount={setIsOpenAddAccount}
        setUserID={setUserID}
        setUserMethodID={setUserMethodID}
        methodsData={methodsItems}
        userData={userData}
        onClose={onCloseUserModal}
      />

      <AddAccountModal
        isOpen={isOpenAddAccount}
        setIsOpen={setIsOpenAddAccount}
        methodsList={methodsItems!}
        methodID={userMethodID!}
        userId={userID!}
        onClose={onCloseAccountsModal}
      />
    </>
  );
};
