import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { MessagesService } from 'shared/services/messages.service';
import { TMessages } from 'shared/services/types/messages.types';

export const useGetMessages = (data: TMessages.GetMessages) => {
  const {
    isLoading: isMessagesLoading,
    data: messages,
    isError: isMessagesError,
    isFetching: isMessagesFetching,
    ...restData
  } = useQuery({
    queryFn: () => MessagesService.getMessages(data),
    queryKey: ['get messages'],
    retry: 0,
    select: ({ data }) => data,
    placeholderData: keepPreviousData
  });

  return { isMessagesLoading, messages, isMessagesError, isMessagesFetching, ...restData };
};
