import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useAuthStore } from 'app/store';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetMyProfile } from 'features/users/api';

import { Layout } from 'shared/ui/layout';

interface IProps extends PropsWithChildren {}

///@ts-ignore
export const ProtectedRoute: FC<IProps> = ({ children }) => {
  const { toogleOtp, updateUser, otp_verified } = useAuthStore();

  const { isPending, data } = useGetMyProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('access'));
  const [refresh, setRefreshToken] = useState(localStorage.getItem('refresh'));

  const isOtpVerified = otp_verified || data?.is_otp_verified;
  useEffect(() => {
    updateUser({ ...data, user: data, otp_verified: true, token: {} });
    toogleOtp(!!data?.is_otp_verified);
  }, [data]);

  useEffect(() => {
    if (!token && !refresh) {
      navigate('/login');
    } else if (token && !isOtpVerified && !isPending) {
      navigate('/two-factor');
    } else if (token && isOtpVerified && ['/login', '/two-factor'].includes(location.pathname)) {
      navigate('/');
    }
  }, [token, location.pathname, isOtpVerified, isPending]);

  const watchStorage = () => {
    setToken(localStorage.getItem('access'));
    setRefreshToken(localStorage.getItem('refresh'));
  };

  useEffect(() => {
    window.addEventListener('storage', watchStorage);
    return () => {
      window.removeEventListener('storage', watchStorage);
    };
  }, []);

  if (children) return <>{children}</>;

  return (
    <>
      <Layout>Error</Layout>
    </>
  );
};
