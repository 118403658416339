import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TAccounts } from 'shared/services/types/accounts.types';
import { UsersService } from 'shared/services/users.service';

export const useSwitchAccounts = () => {
  const queryClient = useQueryClient();

  const switchAccounts = useMutation({
    mutationFn: (data: TAccounts.SwitchAccount) => UsersService.switchAccount(data),
    mutationKey: ['switch user accounts'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get user accounts'] });
    }
  });

  return { switchAccounts };
};
