import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, SxProps, TableCell, TableRow, Theme } from '@mui/material';

import { useContainerHeight } from 'shared/hooks';
import { TAccounts } from 'shared/services/types/accounts.types';
import { TMethods } from 'shared/services/types/methods.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { TableLayout } from 'shared/ui/table-layout';

import { COLUMNS_ACCOUNTS } from '../mock-data/COL_ACCOUNTS';

interface IProps {
  accountsData?: TAccounts.GetUserAccountsSuccess;
  isAccountsFetching?: boolean;
  isAccountsLoading?: boolean;
  onEditAccount: (data: TAccounts.UserAccountItem) => void;
  methods?: TMethods.MethodItem[];
  userIds?: number[];
  setFilter: React.Dispatch<React.SetStateAction<TAccounts.GetUserAccount>>;
  setSwitchItem: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
  setModalAccountAdd: React.Dispatch<React.SetStateAction<boolean>>;
}
export const TableAccounts: FC<IProps> = ({
  accountsData,
  isAccountsLoading,
  onEditAccount,
  setFilter,
  setSwitchItem
}) => {
  const { height, ref } = useContainerHeight(15);
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(0);
  const [checked, setChecked] = useState<boolean>();

  useEffect(() => {
    setFilter(prevState => {
      return { ...prevState, size, page };
    });
  }, [size, page]);

  const customTableStyles = {
    '.MuiTableHead-root': {
      '.MuiTableCell-root': {
        background: '#D9E2FF',
        p: '18px 0',
        height: '20px',
        lineHeight: '20px',
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        height: '20px',
        padding: '16px 0',
        borderBottom: '1px solid #BDC1D2',
        color: '#000',
        fontWeight: '500',
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      }
    }
  } as SxProps<Theme>;

  return (
    <Box
      gridRow={'2/4'}
      gridColumn={'2'}
      sx={{
        gap: 4,
        maxHeight: 1,
        overflow: 'clip'
      }}>
      <Box
        sx={{
          gridTemplateColumns: 'minmax(450px,1fr) minmax(450px, 1fr)',
          gridTemplateRows: '100%',
          gap: 4,
          overflowX: 'hidden'
        }}>
        <Box
          px={12}
          sx={{
            bgcolor: '#fff',
            borderRadius: 4,
            display: 'grid',
            gridTemplateRows: '0 auto',
            minHeight: '350px',
            p: '0',
            height: height
          }}
          ref={ref}>
          <Box
            sx={{
              width: '100%',
              gridRow: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              overflow: 'hidden',
              ...customTableStyles
            }}>
            <TableLayout
              pageSize={size}
              setPageSize={setSize}
              page={page}
              setPage={setPage}
              rows={[]}
              columns={COLUMNS_ACCOUNTS}
              total={0}>
              {isAccountsLoading ? (
                <CircularProgressCenter top='120%' />
              ) : (
                <>
                  {accountsData?.items.map((row, id) => {
                    return (
                      <TableRow key={id}>
                        <TableCell component='th' scope='row'>
                          {++id + accountsData?.page * accountsData?.size}
                        </TableCell>
                        <TableCell align='left'>{row.account_name}</TableCell>
                        <TableCell align='left'>{row.subagent_id}</TableCell>
                        <TableCell align='left' sx={{ cursor: 'pointer' }}>
                          <Box
                            display={'flex'}
                            gap={8}
                            justifyContent={'flex-end'}
                            sx={{ color: '#AEAAAB' }}>
                            <ModeEditOutlineOutlinedIcon
                              onClick={() => {
                                onEditAccount(row);
                              }}
                              style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                            />
                            <StyledSwitch
                              value={checked ? checked : row.is_active}
                              setValue={e => {
                                setSwitchItem(e, row.id);
                                setChecked(!e.target.checked);
                              }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableLayout>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
