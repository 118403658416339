import { useLayoutEffect, useState } from 'react';

import useAuthStore from '../../app/store/useAuthStore';

const useUser = () => {
  const { user } = useAuthStore();
  const [name, setName] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [role, setRole] = useState(user?.role);

  const rolesAccess = (roles: string[]) => {
    if (user) {
      return roles.includes(user.role);
    }
  };

  useLayoutEffect(() => {
    if (user) {
      setName(user.name);
      setCreatedAt(user?.created_at);
      setRole(user?.role);
    }
  }, [user]);

  return { role, createdAt, rolesAccess, name, user };
};
export default useUser;
