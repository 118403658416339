import { FC, useEffect, useState } from 'react';

import { IStepProps } from '..';
import { Add } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, TextField } from '@mui/material';

import { useGetMessages } from 'features/add-method/api/useGetMessages';

import { IModalProps, ModalTemplate } from 'shared/ui/modal-template';

interface IProps extends IModalProps, IStepProps {
  method_id?: number;
  setText: (texts: string[]) => void;
}

export const SelectedTextModal: FC<IProps> = ({
  close,
  isOpen,
  onBack,
  onNext,
  method_id,
  setText
}) => {
  const [activeIndex, setActiveIndex] = useState<number[]>([]);
  const [textList, setTextList] = useState<string[]>([]);
  const [textArea, setTextArea] = useState('');

  const [addTextModalOpen, setAddTextModalOpen] = useState(false);
  const { isMessagesFetching, isMessagesLoading, messages } = useGetMessages({
    page: 0,
    size: 20,
    method_id: method_id?.toString()
  });

  const onSelectedIndex = (id: number) => {
    const newIndex = [...activeIndex];
    if (newIndex.includes(id)) {
      newIndex.splice(newIndex.indexOf(id), 1);
    } else {
      newIndex.push(id);
    }
    setActiveIndex(newIndex);
  };

  const onNextStep = () => {
    setText(textList.filter((item, index) => activeIndex.includes(index)));
    onNext?.();
  };

  const onAddText = () => {
    setTextList(prevState => {
      const textsToAdd = [];
      textsToAdd.push(...prevState, textArea);
      return textsToAdd;
    });
    setAddTextModalOpen(false);
  };

  useEffect(() => {
    if (!!method_id && messages?.items) {
      setTextList(messages!.items.map(i => i.text));
    }
  }, [messages]);

  return (
    <ModalTemplate
      close={close}
      isOpen={isOpen}
      titleText={addTextModalOpen ? 'Add SMS/push' : 'SMS/PUSH'}
      hideBackdrop={false}
      onBack={onBack}>
      <Box p={'34px 24px'} m={'0 auto'}>
        <Box
          display={'flex'}
          sx={{ flexDirection: 'column', position: 'relative' }}
          gap={'16px'}
          maxHeight={'500px'}
          overflow={'auto'}>
          {addTextModalOpen ? (
            <TextField
              multiline
              placeholder='Enter SMS/PUSH text'
              label='SMS/PUSH *'
              rows={7}
              sx={{
                marginTop: '6px'
              }}
              onChange={data => {
                setTextArea(data.target.value);
              }}
            />
          ) : !isMessagesFetching && !isMessagesLoading ? (
            <>
              <Button
                type='button'
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                endIcon={<Add />}
                onClick={() => {
                  setAddTextModalOpen(true);
                }}>
                Add text
              </Button>
              {textList.map((item, i) => (
                <Box
                  key={item}
                  onClick={() => onSelectedIndex(+i)}
                  sx={{ cursor: 'pointer' }}
                  display={'flex'}
                  gap={'14px'}
                  p={'12px 16px'}
                  alignItems={'flex-start'}
                  borderRadius={'4px'}
                  border={'1px solid #BDC1D2'}>
                  <Checkbox sx={{ padding: '0' }} checked={activeIndex.includes(i)} />

                  <Box sx={{ textAlign: 'left', direction: 'ltr', unicodeBidi: 'bidi-override' }}>
                    {item}
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Box
              sx={{
                minWidth: 1,
                minHeight: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Box display={'flex'} gap='10px'>
          <Button
            fullWidth
            disabled={addTextModalOpen ? false : !activeIndex.length}
            variant='contained'
            onClick={addTextModalOpen ? onAddText : onNextStep}
            sx={{
              borderRadius: 50,
              textTransform: 'none',
              height: 40,
              marginTop: 15,
              marginBottom: 3
            }}>
            {addTextModalOpen ? 'Add' : 'Choose'}
          </Button>
        </Box>
      </Box>
    </ModalTemplate>
  );
};
