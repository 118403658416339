import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { MethodsService } from 'shared/services/methods.service';
import { TApplications } from 'shared/services/types/application.types';
import { getTextFromBlob } from 'shared/utils';

export const useGetApplicationsExport = (data: TApplications.getApplicationsExportData) => {
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const {
    isLoading: isApplicationsExporting,
    data: applications,
    isError: isApplicationsExportError,
    refetch: refetchApplications,
    isFetched: isApplicationsFetched,
    ...restData
  } = useQuery({
    queryFn: () => MethodsService.getApplicationsData(data),
    queryKey: ['get applications export', data],
    enabled: false,
    retry: 0
  });

  const getMessageError = async () => {
    //@ts-ignore
    const response = restData.error?.response;
    if (response?.data instanceof Blob) {
      const data = await getTextFromBlob(response?.data);
      setErrorMessage(data);
    } else {
      setErrorMessage(restData.error);
    }
  };

  useEffect(() => {
    getMessageError();
  }, [restData.error]);

  return {
    isApplicationsExporting,
    applications,
    isApplicationsExportError,
    refetchApplications,
    isApplicationsFetched,
    ...restData,
    errorMessage
  };
};
