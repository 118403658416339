import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { MethodsService } from 'shared/services/methods.service';
import { TApplications } from 'shared/services/types/application.types';

export const useGetApplications = (data: TApplications.GetApplication) => {
  const {
    isLoading: isApplicationsLoading,
    data: applications,
    isError: isApplicationsError,
    isFetching: isApplicationsFetching,
    ...restData
  } = useQuery({
    queryFn: () => MethodsService.getApplications(data),
    queryKey: ['get applications', data],
    retry: 0,
    select: ({ data }) => data,
    refetchInterval: 10000,
    placeholderData: keepPreviousData
  });

  return {
    isApplicationsLoading,
    applications,
    isApplicationsError,
    isApplicationsFetching,
    ...restData
  };
};
