import { toFormData } from 'axios';

import { axiosInstance } from 'shared/api';
import { getUserUrl } from 'shared/api/config/api.config';

import { TAccounts } from './types/accounts.types';
import { TUsers } from './types/users.types';

export const UsersService = {
  async getAccounts(data: TAccounts.GetAccount) {
    const response = await axiosInstance.get<TAccounts.GetAccountSuccess>(
      getUserUrl('/get-accounts'),
      {
        params: { ...data }
      }
    );

    return response;
  },
  async createAccount(data: TAccounts.CreateAccount) {
    const response = await axiosInstance.post<TAccounts.CreateAccount>(
      getUserUrl('/create-account'),
      toFormData(data)
    );

    return response;
  },
  async switchAccount(data: TAccounts.SwitchAccount) {
    const response = await axiosInstance.patch<TAccounts.SwitchAccount>(
      getUserUrl('/switch'),
      toFormData(data)
    );

    return response;
  },

  async updateAccount(data: TAccounts.UpdateAccount) {
    const response = await axiosInstance.patch<TAccounts.UpdateAccount>(
      getUserUrl('/update-account'),
      toFormData(data)
    );

    return response;
  },

  async getUserAccounts(data: TAccounts.GetUserAccount) {
    const response = await axiosInstance.get<TAccounts.GetUserAccountsSuccess>(
      getUserUrl('/get-accounts-by-user-and-method'),
      {
        params: data
      }
    );

    return response;
  },

  async getUsers(data: TUsers.GetUsers) {
    const response = await axiosInstance.get<TUsers.GetUsersSuccess>(getUserUrl('/get-users'), {
      params: data
    });

    return response;
  },

  async createUser(data: TUsers.CreateUser) {
    const response = await axiosInstance.post<TUsers.UserData>(getUserUrl('/'), toFormData(data));

    return response;
  },

  async updateUser(data: TUsers.UpdateUser) {
    const response = await axiosInstance.patch<TUsers.UserData>(
      getUserUrl('/update'),
      toFormData(data)
    );

    return response;
  },

  async getMe() {
    const response = await axiosInstance.get<TUsers.UserDataMe>(getUserUrl('/get-current-user'));
    return response;
  }
};
