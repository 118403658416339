import { axiosInstance } from 'shared/api';
import { getMessageUrl } from 'shared/api/config/api.config';

import { TMessages } from './types/messages.types';

export const MessagesService = {
  async getMessages(data: TMessages.GetMessages) {
    const response = await axiosInstance.get<TMessages.MessagesResponse>(
      getMessageUrl('/get-messages'),
      {
        params: data
      }
    );

    return response;
  }
};
