import { REGEX } from 'shared/constants/regex';

export function generateValidPassword(passwordLength: number): string {
  let password: string;
  const generatePassword = () => {
    let charset = '';
    let newPassword = '';

    charset += '!@#$%^&*()'; //useSymbols
    charset += '0123456789'; //useNumbers
    charset += 'abcdefghijklmnopqrstuvwxyz'; //useLowerCase
    charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; //useUpperCase

    for (let i = 0; i < passwordLength; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    return newPassword;
  };

  do {
    password = generatePassword();
  } while (!REGEX.PASSWORD.test(password));

  return password;
}
