//@ts-nocheck
import { createTheme } from '@mui/material/styles';
import type { ClientRectObject } from '@popperjs/core';

export const theme = createTheme({
  palette: {
    primary: { main: '#185AC2' },
    secondary: { main: '#001944' },
    success: { main: '#1EBD8D', dark: '#0F8E68' },
    error: { main: '#BA1A1A' },
    text: {
      primary: '#1B1B1F',
      secondary: '#90939B'
    },
    info: {
      main: '#E3E3E4'
    },
    background: {
      default: '#E2EAF7',
      paper: '#fff'
    },
    divider: '#E9ECF5'
  },
  typography: {
    fontFamily: ['Roboto'].join(',')
  },
  spacing: 2,
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: '#fff',
            color: '#185AC2',
            '&:hover': {
              backgroundColor: '#fff',
              color: '#185AC2'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          borderRadius: 28,
          textTransform: 'none',
          fontWeight: '400',
          boxShadow: 'none'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: 12
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          width: 'auto',
          margin: '0 auto',
          minWidth: '0px',
          color: '#B2C8EB'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: '#A4B1EA'
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        slotProps: {
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: ({ popper }: { popper: ClientRectObject }) => {
                    return [popper.width * 0.3, 0];
                  }
                }
              }
            ]
          }
        }
      },
      styleOverrides: {
        popperArrow: {},
        tooltipArrow: {
          backgroundColor: '#fff',
          border: '1px solid #BDC1D2',
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          padding: '8px 24px',
          borderRadius: '8px',
          color: '#44464F',
          boxShadow: '0px 3.5px 14px 0px rgba(16, 24, 40, 0.20)',
          backdropFilter: 'blur(2px)'
        },
        arrow: {
          color: '#fff',
          '&:before': {
            border: '1px solid #BDC1D2'
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {}
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '.MuiTableCell-root': {
            minWidth: '30px',
            borderBottom: '1px solid #E2EAF7',
            background: '#fff'
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '.MuiTableCell-root': {
            '&:first-child': {
              paddingLeft: '16px'
            },
            '&:last-child': {
              paddingRight: '22px'
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px 0',
          height: '32px',
          fontSize: '16px',
          lineHeight: '20px',
          color: '#44464F',
          border: 'none'
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          minWidth: 24,
          minHeight: 24,
          maxHeight: 24,
          borderRadius: 2,
          margin: '0 .1vw',
          color: '#44464F'
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          minWidth: 10,
          height: 10,
          borderRadius: '50%',
          transform: 'scale(1) translate(2px, -2px)'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: '16px',
          color: '#44464F',
          transform: 'scale(1) translate(14px, -9px)',
          background: '#fff'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          padding: '14px 0 !important',
          color: '#44464F',
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: ' 0.25px',
          position: 'relative',
          '&:after': {
            content: `''`,
            bottom: '0',
            left: '0',
            width: '100%',
            height: '1px',
            position: 'absolute',
            zIndex: '100',
            background: 'rgba(0, 0, 0, 0.23)'
          },
          '& .MuiCheckbox-root': {
            padding: '0'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#BA1A1A',
          bottom: '-28px',
          left: 'calc(50% - 40px)'
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '&#menu-method_id': {
            '.MuiPaper-root': {
              maxHeight: '350px'
            }
          },
          '&#menu-currency': {
            '.MuiPaper-root': {
              maxHeight: '350px'
            }
          },
          '&#menu-methodSelect': {
            '.Mui-selected': {
              background: 'inherit'
            },
            '.MuiPaper-root': {
              maxWidth: '176px',
              maxHeight: '500px',
              marginTop: '10px',
              padding: '0 16px',
              borderRadius: '20px',
              border: '1px solid #185AC2',
              boxSizing: 'border-box'
            },
            '.MuiList-root': {
              padding: '0',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around'
            },
            '.MuiMenuItem-root': {
              padding: '12px 0 10px',
              borderBottom: '1px solid #BDC1D2',
              color: '#185AC2',
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '20px',
              letterSpacing: '0.25px',
              '&:hover': {
                background: 'inherit',
                borderColor: '#185AC2'
              },
              '&:last-child': {
                borderBottom: 'none'
              }
            }
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          justifyContent: 'flex-start',
          gap: '8px',
          padding: '8px 12px',
          '.MuiButtonBase-root': {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            letterSpacing: '0.1px',
            padding: '10px 12px',
            minWidth: '43px',
            margin: '0'
          }
        }
      }
    }
  }
});
