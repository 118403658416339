import { FC } from 'react';

import { PersonAdd } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Box, Button, Checkbox, Grid, Paper, TextField } from '@mui/material';
import { Controller, FieldValues, RegisterOptions, UseFormReturn } from 'react-hook-form';

import { TSelectOption } from 'shared/services/types/common.types';

interface IProps {
  isOptionsLoading?: boolean;
  options: TSelectOption[];
  name: string;
  inputLabel: string;
  inputPlaceholder: string;
  methods: UseFormReturn<FieldValues, any, undefined>;
  headerProps?: {
    onClick: () => void;
    label: string;
  };
  isMulti?: boolean;
  isFooter?: boolean;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

export const SelectSearch: FC<IProps> = ({
  options,
  name,
  inputLabel,
  inputPlaceholder,
  methods,
  headerProps,
  isMulti,
  isFooter,
  rules
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;
  const fieldError = methods?.formState.errors[name]?.message?.toString();

  // TODO переделать
  // useEffect(() => {
  //   const values = methods.control._getWatch(name);
  //   if (values) {
  //     const res = options?.find(item => item.value === values)?.label || '';

  //     console.log(res);
  //   }
  // }, [methods.control._getWatch(name), options]);

  return (
    <>
      <Controller
        control={methods.control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          const currValue = options.find(item => {
            if (typeof value === 'string') return value === item.value;
            return item.value === value?.value;
          });

          const valueIsEmpty = Array.isArray(value) ? !value.length : !value;

          const valueSelect = () => {
            if (['string', 'number'].includes(typeof value)) {
              return value;
            }

            return value || [];
          };

          return (
            <Autocomplete
              multiple={isMulti}
              //TODO value={isMulti ? [currValue] : currValue}
              value={currValue}
              onBlur={onBlur}
              onChange={(event, newValue) => {
                if (Array.isArray(newValue)) {
                  onChange(
                    newValue?.map(item =>
                      ['string', 'number'].includes(typeof item) ? item : item.value
                    )
                  );
                } else {
                  onChange(newValue?.value);
                }
              }}
              // TODO переделать
              // onInputChange={(e, inputValue) => {
              //   if (e?.target && e.type !== 'blur') {
              //     console.log(inputValue);
              //   }
              // }}
              options={options}
              disableCloseOnSelect={isMulti}
              limitTags={isMulti ? 1 : undefined}
              getOptionLabel={option => {
                return option.label ?? '';
              }}
              renderOption={(props, option, { selected }) => {
                const { ...optionProps } = props;
                return (
                  <li {...optionProps} key={option.value}>
                    {isMulti && (
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                    )}
                    {option.label}
                  </li>
                );
              }}
              ListboxComponent={props => (
                /* 
         Кастомный хедер
        */
                <>
                  <Paper
                    {...props}
                    sx={{ maxHeight: '350px !important', padding: '0 16px !important' }}
                    elevation={0}>
                    {/* 
              Кастомный хедер
            */}

                    {!!headerProps && (
                      <Grid
                        container
                        sx={{ padding: '4px 8px', gap: '10px' }}
                        justifyContent='space-between'>
                        <Button
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            '&:hover': { backgroundColor: 'unset' }
                          }}
                          endIcon={<PersonAdd />}
                          onClick={headerProps.onClick}>
                          {headerProps.label}
                        </Button>
                      </Grid>
                    )}
                    {props.children}

                    {/* 
              Кастомный футер
            */}
                    {isFooter && (
                      <Grid
                        container
                        sx={{ padding: '10px 16px', gap: '10px' }}
                        justifyContent='space-between'>
                        <Grid
                          item
                          sx={{
                            color: 'rgba(29, 27, 32, 0.50)',
                            fontDamily: 'Roboto',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            letterSpacing: '0.04px'
                          }}>
                          <Button sx={{ padding: 0 }}>Cancel</Button>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            color: 'rgba(24, 90, 194, 0.20)',
                            fontDamily: 'Roboto',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            letterSpacing: '0.04px'
                          }}>
                          <Button sx={{ padding: 0 }}>Ok</Button>
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </>
              )}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    label={inputLabel}
                    value={valueSelect}
                    placeholder={isMulti && !valueIsEmpty ? '' : inputPlaceholder}
                    // // error={!!errors?.[name]}
                    InputLabelProps={{
                      style: { fontSize: 12 },
                      shrink: true
                    }}
                    sx={{
                      '.MuiAutocomplete-endAdornment': { marginRight: '7px' },
                      fieldset: {
                        border: fieldError && '1px solid #b20000 '
                      }
                    }}
                    variant='outlined'
                    fullWidth
                  />
                );
              }}
            />
          );
        }}
      />
      <Box
        style={{
          marginLeft: '16px',
          fontSize: 12,
          color: '#b20000',
          textAlign: 'start',
          fontWeight: 'normal'
        }}>
        {(fieldError && <span>{fieldError}</span>) || fieldError}
      </Box>
    </>
  );
};
