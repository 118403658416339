import { toFormData } from 'axios';

import { axiosInstance } from 'shared/api';
import { getWithdrawalUrl } from 'shared/api/config/api.config';

import { TWithdrawal } from './types/withdrawal.types';

export const WithdrawalService = {
  async create(data: TWithdrawal.WithdrawalCreate) {
    const response = await axiosInstance.post<TWithdrawal.WithdrawalItem>(
      getWithdrawalUrl('/create'),
      toFormData(data)
    );
    return response;
  },

  async getList() {
    const response = await axiosInstance.get<TWithdrawal.WithdrawalResponse>(
      getWithdrawalUrl('/list')
    );

    return response;
  }
};
