import { useState } from 'react';

const useSortSwitches = () => {
  const [idsAll, setIdsAll] = useState<{ id: number; is_active: boolean }[]>([]);

  const setSwitchItem = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setIdsAll(prev =>
      prev.find(i => i.id === id)
        ? prev.filter(i => i.id !== id)
        : [...prev, { id: id, is_active: e.target.checked }]
    );
  };

  const reset = () => {
    setIdsAll([]);
  };

  return { idsAll, setSwitchItem, reset };
};

export default useSortSwitches;
