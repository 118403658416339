import { useEffect, useRef, useState } from 'react';

import { Add, FilterAlt } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { theme } from 'app/provider/material-ui/theme';
import { useFilterStore } from 'app/store';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetMethods } from 'widgets/methods/api';

import { AddAccountModal } from 'features/add-account';
import { AddMethodModal } from 'features/add-method';
import { AddUserModal } from 'features/add-user';
import { useGetApplicationsExport } from 'features/applications/api';
import { FilterModal } from 'features/filter';
import { MatchTransaction, useChangeApplication } from 'features/match-transaction';
import { useGetTransactionsExport } from 'features/transactions/api';
import { useGetUsers } from 'features/users/api';
import { WithdrawalModal } from 'features/withdrawal';

import { DefaultTables } from 'entities/method-tables/default';
import { MessageModal } from 'entities/transaction-message';

import {
  DownloadCloudIcon,
  EditIcon,
  HeaderArrow,
  WithdrawalHeaderIcon
} from 'shared/assets/icons';
import { useGetDate, useRefetchUsers, useUser } from 'shared/hooks';
import { DateRange, TFilters, TRoles, TStatusType } from 'shared/services/types/common.types';
import { Layout } from 'shared/ui/layout';
import { MethodSelect } from 'shared/ui/method-select';
import { downloadExportFile } from 'shared/utils';
import { addMessageToast } from 'shared/utils/addMessageToast';

const ROLE_ACCESS = ['Администратор', 'Менеджер'] as TRoles[];

export const Method = () => {
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;
  const { user_id, method_id } = useParams();
  const methodID = parseInt(method_id!);
  const { methods: methodsData } = useGetMethods();
  const { getDate } = useGetDate();
  const { match } = useChangeApplication();
  useRefetchUsers();
  const { filter, setFilter, isFilterOpen, setIsFilterOpen } = useFilterStore();
  const { usersData: methodUsers } = useGetUsers({
    method_id: methodID,
    page: 0,
    size: 1
  });
  const addUserBanner = !methodUsers?.items?.length;

  const [bankName, setBankName] = useState('');
  const [error] = useState<any>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [matchApp, setMatchApp] = useState<number>(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [accountNumber, setAccountNumber] = useState('');
  const [status, setStatus] = useState<TStatusType | undefined>();
  const [dateFilter, setDateFilter] = useState<DateRange>([null, null]);
  const [message, setMessage] = useState('');
  const [messageModal, setMessageModal] = useState(false);
  const [filterData, setFilterData] = useState<Record<string, any> | null>(null);

  const onSubmitFilters = (data: TFilters) => {
    setAccountNumber(data.accountNumber);
    setStatus(data.status);
    setDateFilter(data.dateRange);
    setFilterData(data);
    setPage(0);
    setIsFilterOpen(false);
  };

  const onClose = (isReset?: boolean) => {
    if (isReset) {
      setFilterData(filterData);
    }
    reset();
    setIsFilterOpen(false);
  };

  const openMessagesModal = () => {
    setMessageModal(true);
    setMessage(message);
  };

  const matchTransaction = (id: number) => {
    setIsOpen(true);
    setMatchApp(id);
  };

  const onMatchTransaction = async (data: any) => {
    try {
      await match.mutateAsync({
        transaction_id: data.transaction_id,
        application_id: matchApp
      });
      addMessageToast('Matched successfully', 'success');
      setIsOpen(false);
    } catch (error) {
      addMessageToast(error);
    }
  };

  useEffect(() => {
    setIsOpen(false);
    const methodLabel = methodsData?.find(method => method.id === methodID)?.name || '';
    setBankName(methodLabel);
  }, [method_id, methodsData]);

  useEffect(() => {
    setAccountNumber('');
    setDateFilter([null, null]);
    setStatus(undefined);
  }, [methodID]);

  useEffect(() => {
    setFilter({
      date_from: getDate(0, dateFilter),
      date_to: getDate(1, dateFilter),
      account_id: accountNumber,
      status: status ? status : undefined
    });
  }, [methodID, dateFilter, accountNumber, status]);

  const navigate = useNavigate();
  const [currentMethod, setCurrentMethod] = useState<number>();
  const userID = parseInt(user_id!);

  const { methods: methodsList } = useGetMethods();
  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false);
  const [isOpenAddMethod, setIsOpenAddMethod] = useState(false);
  const [isOpenAddUser, setIsOpenAddUser] = useState(false);
  const [isOpenAddAccount, setIsOpenAddAccount] = useState(false);

  const { user } = useUser();

  const [accUserID, setAccUserID] = useState<number | null>(null);

  const downloadTransactionsTrigger = useRef(false);
  const downloadApplicationsDataTrigger = useRef(false);
  const filterParams = {
    methodID: currentMethod,
    ...filter
  };

  const onCloseAccountModal = () => {
    setAccUserID(null);
    setIsOpenAddAccount(false);
  };

  const onCloseUserModal = () => {
    setIsOpenAddUser(false);
  };

  const {
    applications,
    refetchApplications,
    errorMessage: errorApplications,
    isFetching: isApplicationExportFetching
  } = useGetApplicationsExport(filterParams);

  const {
    transactions,
    refetchTransactions,
    errorMessage: errorTransactions,
    isTransactionsExportFetching
  } = useGetTransactionsExport(filterParams);

  const isLoadingDownload = isApplicationExportFetching || isTransactionsExportFetching;

  useEffect(() => {
    if (method_id) {
      setCurrentMethod(parseInt(method_id));
    }
  }, [method_id]);

  useEffect(() => {
    if (errorApplications) {
      addMessageToast(errorApplications?.detail);
    }
  }, [errorApplications]);

  useEffect(() => {
    if (errorTransactions) {
      addMessageToast(errorTransactions?.detail);
    }
  }, [errorTransactions]);

  const handleDownloadData = () => {
    refetchApplications();
    refetchTransactions();
    downloadApplicationsDataTrigger.current = true;
    downloadTransactionsTrigger.current = true;
  };

  useEffect(() => {
    if (applications && downloadApplicationsDataTrigger.current) {
      downloadExportFile(applications, 'applications');

      downloadApplicationsDataTrigger.current = false;
    }
  }, [applications]);
  useEffect(() => {
    if (transactions && downloadTransactionsTrigger.current) {
      downloadExportFile(transactions, 'transactions');
      downloadTransactionsTrigger.current = false;
    }
  }, [transactions]);

  const currentMethodData = methodsList?.find(method => method.id === currentMethod);

  const filterActive = !!currentMethod && Object.values(filter).some(item => item);

  return (
    <>
      <Layout bankName={bankName} isSingleMethod>
        <Box
          gridColumn='2'
          gridRow='1'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 10,
            py: 7,
            px: 12,
            borderRadius: 4,
            bgcolor: '#fff',
            '.MuiButton-root': {
              padding: ' 10px 28px 10px 24px',
              minWidth: 'fit-content',
              maxHeight: 44,
              fontSize: 16,
              fontWeight: 500,
              letterSpacing: '0.25px',
              '&.MuiButton-contained': {
                fontSize: '14px',
                fontWeight: '400'
              }
            }
          }}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '14px'
            }}>
            <Box sx={{ height: '44px', cursor: 'pointer' }}>
              <HeaderArrow
                style={{
                  cursor: 'pointer',
                  marginTop: '11.5px',
                  color: '#185AC2'
                }}
                onClick={() => {
                  navigate('/');
                }}
              />
            </Box>
            <Typography
              variant='h3'
              color='primary'
              sx={{ fontSize: 28, lineHeight: '36px', fontWeight: '500' }}>
              Method
            </Typography>
            <Box>
              <MethodSelect methodsList={methodsList!} useParamsMethodID={method_id!} />
            </Box>
          </Box>
          {!addUserBanner && user && ROLE_ACCESS.includes(user?.role) && (
            <Button
              variant='contained'
              onClick={() => {
                setIsOpenAddUser(true);
              }}
              startIcon={<Add />}></Button>
          )}

          {user && ROLE_ACCESS.includes(user?.role) && (
            <>
              <Button
                variant='outlined'
                sx={{
                  '.MuiButton-startIcon': {
                    padding: '4px'
                  }
                }}
                startIcon={<EditIcon width={'24px'} height={'24px'} />}
                onClick={() => {
                  setIsOpenAddMethod(true);
                }}>
                Edit method
              </Button>
              {!addUserBanner && (
                <Button
                  variant='outlined'
                  disabled={isLoadingDownload}
                  sx={{
                    position: 'relative'
                  }}
                  startIcon={<DownloadCloudIcon />}
                  onClick={handleDownloadData}>
                  {filterActive && (
                    <Box
                      sx={{
                        width: 6,
                        height: 6,
                        borderRadius: '50%',
                        backgroundColor: 'red',
                        position: 'absolute',
                        top: 12,
                        right: 19
                      }}
                    />
                  )}
                  Download data
                </Button>
              )}
            </>
          )}
          {!addUserBanner && (
            <Button
              variant='outlined'
              sx={{
                '.MuiButton-startIcon': {
                  padding: '4px'
                }
              }}
              startIcon={<WithdrawalHeaderIcon />}
              onClick={() => {
                setIsOpenWithdrawal(true);
              }}>
              Withdrawal
            </Button>
          )}
          {!addUserBanner && !userID && (
            <Button
              variant='outlined'
              onClick={() => {
                setIsFilterOpen(true);
              }}
              startIcon={<FilterAlt />}>
              Filter
            </Button>
          )}
        </Box>
        {addUserBanner ? (
          <Box
            bgcolor={theme.palette.primary.main}
            sx={{
              gridRow: '2',
              gridColumn: '2',
              padding: '14px 16px',
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxWidth: 1
            }}>
            <Box>
              <Typography
                variant='body2'
                sx={{
                  color: 'white',
                  fontSize: 24,
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  lineHeight: '36px',
                  wordWrap: 'break-word'
                }}>
                The method doesn't have any users yet
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  color: 'white',
                  fontSize: 18,
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  lineHeight: '30px',
                  wordWrap: 'break-word'
                }}>
                The method cannot work without users. Add a user or create a new one.
              </Typography>
            </Box>
            {user && ROLE_ACCESS.includes(user?.role) && (
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  setIsOpenAddUser(true);
                }}
                startIcon={<Add />}>
                Add user
              </Button>
            )}
          </Box>
        ) : (
          <DefaultTables
            matchTransaction={matchTransaction}
            methodID={methodID}
            pageSize={pageSize}
            setPageSize={setPageSize}
            page={page}
            setPage={setPage}
            status={status}
            accountNumber={accountNumber}
            dateFilter={dateFilter}
            onSubmitFilters={onSubmitFilters}
            openMessagesModal={openMessagesModal}
          />
        )}
        <FilterModal
          methodID={methodID}
          onSubmit={onSubmitFilters}
          onClose={onClose}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
          methods={methods}
          reset={reset}
          userData={filterData}
        />
        <MatchTransaction
          onSubmit={onMatchTransaction}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          error={error}
        />
        <MessageModal message={message} isOpen={messageModal} setIsOpen={setMessageModal} />

        <AddMethodModal
          isOpen={isOpenAddMethod}
          onClose={() => setIsOpenAddMethod(false)}
          methodData={currentMethodData}
        />
        <WithdrawalModal isOpen={isOpenWithdrawal} setIsOpen={setIsOpenWithdrawal} />
        <AddUserModal
          isOpen={isOpenAddUser}
          setIsOpen={setIsOpenAddUser}
          setIsOpenAddAccount={setIsOpenAddAccount}
          setUserID={setAccUserID}
          onClose={onCloseUserModal}
          methodID={methodID}
          methodsData={methodsData}
        />

        <AddAccountModal
          methodsList={methodsList!}
          isOpen={isOpenAddAccount}
          setIsOpen={setIsOpenAddAccount}
          userId={accUserID ?? user?.id}
          onClose={onCloseAccountModal}
          methodID={currentMethod!}
        />
      </Layout>
    </>
  );
};
