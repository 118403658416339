import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { BanksService } from 'shared/services/banks.service';
import { TBanks } from 'shared/services/types/banks.types';

export const useGetBanks = (data: TBanks.GetBanks) => {
  const {
    isLoading: isBanksLoading,
    data: banksData,
    isError: isBanksError,
    isFetching: isBanksFetching,
    ...restData
  } = useQuery({
    queryFn: () => BanksService.getBanks(data),
    queryKey: ['get banks', data],
    retry: 0,
    select: ({ data }) => data,
    placeholderData: keepPreviousData
  });

  return { isBanksLoading, banksData, isBanksError, isBanksFetching, ...restData };
};
