import { useState } from 'react';

import { CheckCircleRounded, RadioButtonUncheckedRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Typography
} from '@mui/material';
import { useAuthStore } from 'app/store';
import { FieldValues, useForm } from 'react-hook-form';

import { useActionAuth } from 'features/auth/api';

import { REGEX } from 'shared/constants';
import { AuthService } from 'shared/services/auth/auth.service';
import { TAuth } from 'shared/services/types/auth.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { addMessageToast } from 'shared/utils';

import styles from './login.module.sass';

interface IValidForm {
  email?: boolean;
  password?: boolean;
}

export const LoginModal = () => {
  const [resetPass, setResetPass] = useState(false);
  const [, setSubmited] = useState<IValidForm>({
    email: false,
    password: false
  });
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;
  const { loginUser } = useActionAuth();
  const { toogleOtp, updateUser, logout } = useAuthStore();

  const onSubmit = async (data: FieldValues) => {
    setSubmited({ email: true, password: true });
    try {
      const loginData = data as TAuth.Login;
      const response = await loginUser.mutateAsync(loginData);
      const role = response.data.user?.role;
      if (!['Агент'].includes(role || '')) {
        updateUser(response.data);
        toogleOtp(response.data.otp_verified);
      } else {
        AuthService.logout();
        logout();
        addMessageToast(`Your “${role}” role does not have access `);
      }
    } catch (error) {
      addMessageToast(error, 'error');
    }
  };

  return (
    <ModalTemplate
      isConfirmationNeeded={false}
      close={() => {}}
      isDark={false}
      hideCloseIcon
      showBGimg
      isOpen
      titleText={resetPass ? 'Reset password' : 'Sing in to CashDash'}
      hideBackdrop={true}
      subtitleText={
        resetPass
          ? 'Enter your email to reset your password'
          : 'Enter your login and password to sign in'
      }
      reset={reset}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid container direction={'column'} spacing={20} wrap={'nowrap'} position={'relative'}>
            {!resetPass && (
              <>
                <Grid item>
                  <Input
                    name='email'
                    rules={{
                      required: true,
                      minLength: { value: 1, message: 'Wrong login' },
                      pattern: {
                        value: REGEX.EMAIL,
                        message: 'Please, enter valid email'
                      }
                    }}
                    label='Login *'
                    placeholder='Your login'
                    methods={methods}
                  />
                </Grid>
                <Grid item sx={{ pt: '24px !important' }}>
                  <Input
                    name='password'
                    type={'password'}
                    rules={{
                      required: true,
                      minLength: { value: 4, message: 'Too short password' }
                    }}
                    label='Password *'
                    placeholder='***'
                    methods={methods}
                  />
                </Grid>
              </>
            )}
            {resetPass && (
              <>
                <Grid item>
                  <Input
                    name='email_reset'
                    rules={{
                      required: true,
                      minLength: { value: 4, message: 'Too short password' }
                    }}
                    label='Your email *'
                    placeholder='Your login'
                    methods={methods}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {resetPass ? (
            <Divider sx={{ width: 1, my: 12 }}></Divider>
          ) : (
            <Divider sx={{ width: 1, pt: 14 }}></Divider>
          )}
          {!resetPass && (
            <>
              <Grid
                container
                columns={2}
                wrap={'nowrap'}
                sx={{ justifyContent: 'space-between', py: 8 }}>
                <Grid item>
                  <FormControlLabel
                    sx={{
                      marginLeft: '-4px',
                      '.MuiTypography-root': {
                        fontSize: 14,
                        lineHeight: '20px',
                        color: '#90939B'
                      },
                      '.MuiSvgIcon-fontSizeMedium': { color: '#BDC1D2', fontSize: 22 },
                      '.Mui-checked .MuiSvgIcon-fontSizeMedium': {
                        color: '#1EBD8D'
                      },
                      '.Mui-checked + .MuiTypography-root': {
                        color: '#44464F'
                      }
                    }}
                    control={
                      <Checkbox
                        value='true'
                        color='success'
                        sx={{ p: 1 }}
                        {...methods.register('checkbox_remember')}
                        icon={<RadioButtonUncheckedRounded />}
                        checkedIcon={<CheckCircleRounded />}
                      />
                    }
                    label='Remember me'
                  />
                </Grid>
                <Grid item>
                  <Link
                    component='button'
                    type='button'
                    variant='body2'
                    sx={{ height: 1 }}
                    onClick={() => {
                      // setError(null);
                      methods.reset({});
                      setResetPass(true);
                    }}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <Button
          type='submit'
          fullWidth
          disabled={loginUser.isPending}
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none' }}
          onClick={() => {
            setSubmited({ email: true, password: true });
          }}>
          {resetPass ? 'Reset password' : 'Sign In'}
        </Button>
        {resetPass && (
          <Box display='flex' alignItems='center'>
            <Typography
              onClick={() => {
                methods.reset({});
                setResetPass(false);
                setSubmited({ email: false, password: false });
              }}
              className={resetPass && styles.subButton}>
              Get Back
            </Typography>
          </Box>
        )}
      </FormWrapper>
    </ModalTemplate>
  );
};
