import { FC, useEffect, useState } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useActionUsers } from 'features/users/api';

import { REGEX, ROLE_USER } from 'shared/constants';
import { TMethods } from 'shared/services/types/methods.types';
import { TUsers } from 'shared/services/types/users.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { Select } from 'shared/ui/select';
import { addMessageToast, hasFormValuesChanged } from 'shared/utils';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userData?: TUsers.UserData;
  methodsData?: TMethods.MethodItem[];
  onClose: () => void;
  required?: string;
  methodID?: number;
  technicalMethodLabel?: string;
  technicalMethodSource?: string;
  setIsOpenAddAccount: React.Dispatch<React.SetStateAction<boolean>>;
  setUserID: React.Dispatch<React.SetStateAction<number | null>>;
  setUserMethodID?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const AddUserModal: FC<IProps> = ({
  isOpen,
  setIsOpen,
  methodsData,
  userData,
  onClose,
  required,
  methodID,
  setIsOpenAddAccount,
  setUserID,
  setUserMethodID
}) => {
  const [isPassForgot, setIsPassForgot] = useState(!!userData);

  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;

  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), userData ?? {}, ['method_id']);

  const methodData = methodsData?.find(method => method.id === methodID);
  const methodName = methodData?.name;

  useEffect(() => {
    setIsPassForgot(!!userData);
    if (userData) {
      reset(userData);
    }
  }, [userData]);

  const { create, update } = useActionUsers();

  const onSubmit: SubmitHandler<TUsers.CreateUser> = async data => {
    try {
      if (!userData) {
        const { data: user } = await create.mutateAsync({ ...data, method_id: methodID });
        addMessageToast('User created', 'success');
        if (['Агент', 'Приложение'].includes(user.role)) {
          setUserID(user.id);
          setUserMethodID?.(user.method_id);
          setIsOpenAddAccount(true);
        } else {
          reset();
        }
      } else {
        await update.mutateAsync({ ...data, user_id: userData.id });
        addMessageToast('User updated', 'success');
      }
      onClose();
      reset([]);
    } catch (error) {
      addMessageToast(error);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={onClose}
      isOpen={isOpen}
      titleText={userData ? 'Edit user' : 'Add user'}
      hideBackdrop={false}
      onBack={() => setIsOpen(false)}
      reset={() => {
        reset();
        methods.clearErrors();
      }}
      maxWidth={413}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid
            container
            direction={'column'}
            columnGap={'20px'}
            rowGap={15}
            wrap={'nowrap'}
            gridTemplateColumns={'1fr '}
            display='grid'
            gridTemplateRows='auto auto 1fr'>
            <Grid item>
              <Input
                methods={methods}
                placeholder='Text'
                label='Email *'
                name='email'
                rules={{
                  required: required,
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Enter the correct email'
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Input
                methods={methods}
                placeholder='Text'
                label='Name *'
                name='name'
                rules={{ required: required }}
              />
            </Grid>
            <Grid
              key={isPassForgot.toString()}
              item
              sx={{
                order: userData ? '100' : '0',
                marginTop: isPassForgot ? '-14px' : '0',
                position: 'relative'
              }}>
              {isPassForgot ? (
                <Button
                  variant='text'
                  onClick={() => setIsPassForgot(false)}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    fontSize: '16px',
                    fontWeight: '500'
                  }}>
                  Forgot password?
                </Button>
              ) : (
                <Input
                  methods={methods}
                  placeholder='Text'
                  label={userData ? 'New password *' : 'Password *'}
                  name='password'
                  isPass
                  rules={{
                    required: required,
                    minLength: { message: 'Minimum length - 10', value: 10 }
                  }}
                />
              )}
            </Grid>
            <Grid item>
              {methodData !== undefined ? (
                <Input
                  autofill
                  defaultValue={methodName}
                  name='method_id'
                  label='Method'
                  placeholder='Method name'
                  methods={methods}
                />
              ) : (
                <Select
                  name='method_id'
                  placeholder='Text'
                  label='Method *'
                  rules={{
                    required: required
                  }}
                  options={methodsData?.map(item => ({ label: item.name, value: item.id })) || []}
                />
              )}
            </Grid>

            <Grid item>
              <Select
                name='role'
                placeholder='Text'
                label='Role *'
                rules={{
                  required: required
                }}
                options={ROLE_USER.map(item => ({ label: item.value, value: item.label }))}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={'30px'}
          display={'flex'}
          gap={'16px'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Button
            variant='contained'
            fullWidth
            onClick={() => setIsOpen(false)}
            sx={{
              maxWidth: 175,
              m: 0,
              borderRadius: 50,
              textTransform: 'none',
              background: '#E3E3E4',
              color: '#1D1B20',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#e2e2e2'
              },
              height: 40
            }}>
            Cancel
          </Button>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{
              m: 0,
              borderRadius: 50,
              textTransform: 'none',
              maxWidth: 175,
              height: 40,
              boxShadow: 'none'
            }}>
            Save
          </Button>
        </Box>
      </FormWrapper>
    </ModalTemplate>
  );
};
