import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, SxProps, TableCell, TableRow, Theme, Tooltip } from '@mui/material';
import { useAuthStore } from 'app/store';
import { useNavigate } from 'react-router-dom';

import { UserAccountAddIcon, UserAccountIcon } from 'shared/assets/icons';
import { ROLE_USER } from 'shared/constants';
import { useContainerHeight } from 'shared/hooks';
import { TMethods } from 'shared/services/types/methods.types';
import { TUsers } from 'shared/services/types/users.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { TableLayout } from 'shared/ui/table-layout';

import { COLUMNS_USERS } from '../mock-data/COL_USERS';

interface IProps {
  usersData?: TUsers.GetUsersSuccess;
  isUsersFetching?: boolean;
  isUsersLoading?: boolean;
  onEditUser: (data: TUsers.UserData) => void;
  methodsItems?: TMethods.MethodItem[];
  onToggleUsers?: (id: number) => void;
  setFilter: React.Dispatch<
    React.SetStateAction<{
      page: number;
      size: number;
    }>
  >;
  setSwitchItem: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
  setModalAccountAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setUserMethodID: React.Dispatch<React.SetStateAction<number | null>>;
  setUserID: React.Dispatch<React.SetStateAction<number | null>>;
}
export const TableUsers: FC<IProps> = ({
  usersData,
  isUsersLoading,
  onEditUser,
  methodsItems,
  setFilter,
  setSwitchItem,
  setModalAccountAdd,
  setUserMethodID,
  setUserID
}) => {
  const { height, ref } = useContainerHeight(15);
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(0);
  const [checked, setChecked] = useState<boolean>();

  const { user } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    setFilter({ size, page });
  }, [size, page]);

  const customTableStyles = {
    '.MuiTableHead-root': {
      '.MuiTableCell-root': {
        background: '#D9E2FF',
        p: '18px 0',
        height: '20px',
        lineHeight: '20px',
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        height: '20px',
        padding: '16px 0',
        borderBottom: '1px solid #BDC1D2',
        color: '#000',
        fontWeight: '500',
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      }
    }
  } as SxProps<Theme>;

  return (
    <Box
      gridRow={'2/4'}
      gridColumn={'2'}
      sx={{
        gap: 4,
        maxHeight: 1,
        overflow: 'clip'
      }}>
      <Box
        sx={{
          gridTemplateColumns: 'minmax(450px,1fr) minmax(450px, 1fr)',
          gridTemplateRows: '100%',
          gap: 4,
          overflowX: 'hidden'
        }}>
        <Box
          px={12}
          sx={{
            bgcolor: '#fff',
            borderRadius: 4,
            display: 'grid',
            gridTemplateRows: '0 auto',
            minHeight: '350px',
            p: '0',
            height: height
          }}
          ref={ref}>
          <Box
            sx={{
              width: '100%',
              gridRow: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              overflow: 'hidden',
              ...customTableStyles
            }}>
            <TableLayout
              pageSize={size}
              setPageSize={setSize}
              page={page}
              setPage={setPage}
              rows={usersData?.items || []}
              columns={COLUMNS_USERS}
              total={usersData?.total || 0}>
              {isUsersLoading ? (
                <CircularProgressCenter top='120%' />
              ) : (
                <>
                  {usersData?.items?.map((row, id) => {
                    return (
                      <TableRow key={id}>
                        <TableCell component='th' scope='row'>
                          {id + 1 + usersData?.page * usersData?.size}
                        </TableCell>
                        <TableCell align='left'>{row.email}</TableCell>
                        <TableCell component='th' scope='row'>
                          {row.name}
                        </TableCell>
                        <TableCell align='left'>
                          {methodsItems?.find(item => item.id === row.method_id)?.name}
                        </TableCell>
                        <TableCell align='left'>
                          {ROLE_USER.find(item => item.label === row.role)?.value}
                        </TableCell>
                        <TableCell align='left' sx={{ cursor: 'pointer' }}>
                          <Box
                            display={'flex'}
                            gap={8}
                            justifyContent={'flex-end'}
                            sx={{ color: '#AEAAAB' }}>
                            {user &&
                              ['Администратор', 'Менеджер'].includes(user?.role) &&
                              ['Агент', 'Приложение'].includes(row.role) && (
                                <Tooltip
                                  title={row.has_accounts ? 'User accounts' : 'Add user accounts'}
                                  arrow
                                  disableInteractive>
                                  {row.has_accounts ? (
                                    <UserAccountIcon
                                      onClick={() => {
                                        navigate(`/user-accounts/${row.id}/${row.method_id}`);
                                      }}
                                      style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                                    />
                                  ) : (
                                    <UserAccountAddIcon
                                      onClick={() => {
                                        setUserMethodID(row.method_id);
                                        setUserID(row.id);
                                        setModalAccountAdd(true);
                                      }}
                                      style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                                    />
                                  )}
                                </Tooltip>
                              )}
                            <ModeEditOutlineOutlinedIcon
                              onClick={() => {
                                onEditUser(row);
                              }}
                              style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                            />
                            <StyledSwitch
                              value={checked ? checked : row.is_active}
                              setValue={e => {
                                setSwitchItem(e, row.id);
                                setChecked(!e.target.checked);
                              }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableLayout>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
