import { FC, useEffect } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import { REGEX } from 'shared/constants/regex';
import { TAccounts } from 'shared/services/types/accounts.types';
import { TMethods } from 'shared/services/types/methods.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { addMessageToast, hasFormValuesChanged } from 'shared/utils';

import { useCreateAccount } from '../api';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  methodID?: number;
  userId?: number;
  methodsList: TMethods.MethodItem[];
  onClose: () => void;
  accountData?: TAccounts.UserAccountItem;
}

export const AddAccountModal: FC<IProps> = ({
  isOpen,
  setIsOpen,
  methodID,
  methodsList,
  accountData,
  userId,
  onClose
}) => {
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;

  const isConfirmationNeeded = hasFormValuesChanged(methods.watch(), accountData ?? {}, [
    'method_id'
  ]);

  const methodData = methodsList?.find(method => method.id === methodID);
  const methodName = methodData?.name;
  const currency = methodData?.currency;

  const source = methodData?.transaction_source;

  const isSourceEmail = source === 'email';

  useEffect(() => {
    if (accountData) {
      reset(accountData);
    }
  }, [accountData]);

  useEffect(() => {
    if (!isOpen) {
      reset({});
    }
  }, [isOpen]);

  const { create, update } = useCreateAccount();

  const onSubmit = async (data: TAccounts.CreateAccount | TAccounts.UpdateAccount) => {
    const resultData = {
      ...data,
      method_id: methodID,
      currency: currency,
      user_id: userId,
      account_id: data.subagent_id
    };
    try {
      if (!accountData) {
        await create.mutateAsync({ ...resultData });
        addMessageToast('Account successfully created', 'success');
      } else {
        await update.mutateAsync({
          ...resultData,
          id: accountData.id,
          subagent_id: accountData.subagent_id
        });
        addMessageToast('Account successfully updated', 'success');
      }
      setIsOpen(false);
      methods.reset();
    } catch (error) {
      addMessageToast(error);
    }
  };

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={() => {
        onClose();
        reset();
      }}
      isOpen={isOpen}
      titleText={accountData ? 'Edit account' : 'Add account'}
      hideBackdrop={false}
      reset={reset}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
            <Grid item>
              <Input
                autofill
                defaultValue={methodName}
                name='method_id'
                label='Method *'
                placeholder='Method Name'
                methods={methods}
              />
            </Grid>
            <Grid item>
              <Input
                name='account_name'
                label={isSourceEmail ? 'Email *' : `Enter account name *`}
                placeholder={isSourceEmail ? 'Email *' : `Account name *`}
                rules={{
                  required: `Please, enter ${isSourceEmail ? 'email' : 'your account name'} *`,
                  pattern: {
                    value: source === 'email' ? REGEX.EMAIL : REGEX.USERNAME,
                    message: `Enter correct ${isSourceEmail ? 'email' : 'account name'}  *`
                  }
                }}
                methods={methods}
              />
            </Grid>
            {isSourceEmail && (
              <Grid item>
                <Input
                  isPass
                  name='password'
                  type={'password'}
                  label='Password from email*'
                  placeholder='***************'
                  rules={{ required: 'Please, enter password' }}
                  methods={methods}
                />
              </Grid>
            )}
            <Grid item>
              <Input
                name='subagent_id'
                label='Subaccount *'
                placeholder='Subaccount id'
                rules={{ required: 'Please, enter subaccount id' }}
                methods={methods}
              />
            </Grid>

            {source === 'api' && (
              <>
                <Grid item>
                  <Input
                    name='bank_login'
                    label='Login from bank account *'
                    placeholder='Bank login'
                    rules={{ required: 'Please, enter your bank login' }}
                    methods={methods}
                  />
                </Grid>
                <Grid item>
                  <Input
                    isPass
                    name='bank_password'
                    type={'password'}
                    label='Password from bank account *'
                    placeholder='***************'
                    rules={{
                      required: 'Please, enter password',
                      pattern: {
                        value: REGEX.PASSWORD,
                        message: 'Enter correct password'
                      }
                    }}
                    methods={methods}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        <Box display={'flex'} gap='10px'>
          <Button
            fullWidth
            variant='outlined'
            onClick={() => {
              setIsOpen(false);
            }}
            sx={{
              borderRadius: 50,
              textTransform: 'none',
              height: 40,
              marginTop: 15,
              marginBottom: 3
            }}>
            Cancel
          </Button>
          <Button
            fullWidth
            type='submit'
            variant='contained'
            sx={{
              borderRadius: 50,
              textTransform: 'none',
              height: 40,
              marginTop: 15,
              marginBottom: 3
            }}>
            Save
          </Button>
        </Box>
      </FormWrapper>
    </ModalTemplate>
  );
};
