import { GridColDef } from '@mui/x-data-grid';

import { ROWS } from 'widgets/methods/mock-data/MOCK_ROW';

const FULL_TABLE_WIDTH = 1790;

export const COLUMNS_ACCOUNTS: GridColDef<(typeof ROWS)[number]>[] = [
  {
    field: 'id',
    headerName: '№',
    width: 467 / FULL_TABLE_WIDTH
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 494 / FULL_TABLE_WIDTH
  },
  {
    field: 'sub_id',
    headerName: 'Subaccount',
    width: 634 / FULL_TABLE_WIDTH
  },
  {
    field: 'edit',
    headerName: '',
    width: 96 / FULL_TABLE_WIDTH
  }
];
