import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { TAccounts } from 'shared/services/types/accounts.types';
import { UsersService } from 'shared/services/users.service';

export const useGetUserAccounts = (data: TAccounts.GetUserAccount) => {
  const {
    isLoading: isAccountsLoading,
    data: accountsData,
    isError: isAccountsError,
    isFetching: isAccountsFetching,
    ...restData
  } = useQuery({
    queryFn: () => UsersService.getUserAccounts(data),
    queryKey: ['get user accounts', data],
    retry: 0,
    select: ({ data }) => data,
    placeholderData: keepPreviousData
  });

  return { isAccountsLoading, accountsData, isAccountsError, isAccountsFetching, ...restData };
};
